<template>
	<section id="newsItemStepPage">
		<Modal v-if="themeSelector.active" :close="() => themeSelector.active = false" width="800px" id="themeSelector">
			<h1>Kies een thema</h1>
			<p>Kies een thema om het nieuwsbericht onder te plaatsen.</p>
			<ul>
				<li v-for="theme in themeSelector.item.themes" :key="theme.id" @click="moveToSelected(theme.id)">
					{{theme.title}}
				</li>
			</ul>
			<div class="btn btn-transparant" @click="themeSelector.active = false">Annuleren</div>
		</Modal>
		<article class="top-section">
			<div>
				<h1>Nieuwsbrief samenstellen</h1>
				<label>Nieuwsberichten zoeken</label>
				<el-date-picker
      		v-model="newsItemSearch.date"
					id="newsitemsDatepicker"
					type="daterange"
					range-separator="Tot"
					start-placeholder="Begin datum"
					end-placeholder="Eind datum"
					>
				</el-date-picker>
			</div>
			<div>
				<ul class="newsletter-steps">
					<router-link :to="{name: 'NewsItemStep'}" tag="li">
						<span>1</span>
						<div>Nieuwsberichten</div>
					</router-link>
					<router-link :to="{name: 'AdvertisementStep'}" tag="li">
						<span>2</span>
						<div>Advertentie</div>
					</router-link>
					<router-link :to="{name: 'CalendarItemStep'}" tag="li">
						<span>2</span>
						<div>Agenda items</div>
					</router-link>
				</ul>
				<router-link :to="{name: 'AdvertisementStep'}" class="btn btn-primary" id="toCalendarBtn" tag="div">
					Naar advertenties
					<img src="@/assets/right-arrow.svg" height="20px" alt="">
				</router-link>
			</div>
		</article>
		<article id="newItemsSelection">
			<div>
				<h2>Nieuwsberichten</h2>
				<ul id="newsletterSearchList">
					<li v-for="newsItem in filteredNewsItems" :key="newsItem.id">
						<div class="newsletterSearchListTitle">
							{{newsItem.title}}
						</div>
						<div class="addItem" @click="openThemeSelector(newsItem)">
							<img src="@/assets/right-arrow.svg" alt="">
						</div>
					</li>
				</ul>
			</div>
			<div id="newsletterSelectedItems">
				<h2>Geselecteerde nieuwsberichten</h2>
				<div v-for="theme in newNewsletterItems" :key="theme.id" >
					<div v-if="theme.items.length > 0">
						<b>{{theme.title}}</b>
						<ul class="sortable-list">
							<draggable v-model="theme.items" :group="theme.id" @start="drag=true" @end="drag=false">
									<li v-for="(newsItem, i) in theme.items" :key="newsItem.id">
										<div class="removeItem" @click="removeSelectedItem(newsItem.id, theme.id)">
											<img src="@/assets/left-arrow.svg" alt="">
										</div>
										<div class="newsletterSearchListTitle">
											{{newsItem.title}}
										</div>
										<div class="itemPosition">
											{{i + 1}}
										</div>
									</li>
							</draggable>
						</ul>
					</div>
				</div>
			</div>
		</article>
	</section>
</template>

<script>
import { mapGetters } from 'vuex';
import draggable from 'vuedraggable'

export default {
	data () {
		return {
			newsItemSearch: {
				date: [new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1), new Date((new Date()).getFullYear(), (new Date()).getMonth() + 1, 0)]
			},
			themeSelector: {
				active: false,
				item: {}
			}
		}
	},
	watch: {
		'newsItemSearch.date' (date) {
			this.fetchNewsItems()
		}
	},
	components: {
			draggable,
	},
	computed: {
		...mapGetters({
			newsItems: 'newsItem/newsItems',
			themes: 'theme/themes',
			newNewsletterItems: 'newsletter/newNewsletterItems'
		}),
		filteredNewsItems() {
			let ids = this.newNewsletterItems.map(s => s.items).flat().map(i => i.id)
			return this.newsItems.filter(n => !ids.includes(n.id))
		},
	},
	created () {
		this.fetchNewsItems();

		if(this.newNewsletterItems.length < 1) {
			this.$store.dispatch('theme/fetchThemes').then(() => {
				this.$store.commit('newsletter/setNewNewsletterItems', this.themes.map(t => { return {id: t.id, title: t.title, items: []}}))
			});
		}
	},
	mounted () {
	},
	methods: {
		fetchNewsItems () {
			let dateFrom = ''
			let dateTill = ''

			if(this.newsItemSearch.date[0]) {
				dateFrom = this.newsItemSearch.date[0].toLocaleDateString("nl-NL")
				dateTill = this.newsItemSearch.date[1].toLocaleDateString("nl-NL")
			}
			this.$store.dispatch('newsItem/fetchNewsItems', {publication_date_from: dateFrom , publication_date_till: dateTill, limit: 1000})
		},
		moveToSelected (themeId) {
			let theme = this.newNewsletterItems.find(n => n.id == themeId)
			theme.items.push({
				id: this.themeSelector.item.id,
				title: this.themeSelector.item.title
			})
			this.themeSelector.active = false
		},
		openThemeSelector (item) {
			console.log(item)
			if(item.themes.length == 1) {
				this.themeSelector = {
					active: false,
					item: item
				}
				this.moveToSelected(item.themes[0].id)
			} else {
				this.themeSelector = {
					active: true,
					item: item
				}
			}
		},
		removeSelectedItem (itemId, themeId) {
			let theme = this.newNewsletterItems.find(t => t.id == themeId)
			theme.items = theme.items.filter(i => i.id !== itemId)
		}
	}
}
</script>
<style lang="scss">
#app #newsItemStepPage {
	height: 100%;
	padding: 30px;
	#toCalendarBtn {
		img {
			margin-left: 10px;
		}
	}
	#themeSelector {
		h1 {
			margin: 0;
		}
		p {
			margin: 10px 0px 30px;
		}
		.btn {
			margin-top: 30px;
		}
		ul {
			padding: 0;
			list-style-type: none;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap: 10px;
			grid-row-gap: 10px;
			li {
				padding: 10px 20px;
				border: 1px solid #ccc;
				border-radius: 5px;
				cursor: pointer;
				&:hover {
					border-color: #E04E39;
				}
			}
		}
	}
	> article {
		&.top-section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			h1 {
				margin-bottom: 40px;
			}
			.el-date-editor {
				max-width: 400px;
			}
		}
	}
	#newItemsSelection {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 40px;
		h2 {
			margin: 30px 0 10px;
		}
		#newsletterSearchList {
			list-style-type: none;
			padding: 0;
			margin: 0;
			border-top: 2px solid #E04E39;
			li {
				border-bottom: 1px solid #DCDCDC;
				border-left: 1px solid #DCDCDC;
				border-right: 1px solid #DCDCDC;
				display: grid;
				grid-template-columns: 1fr 40px;
				.newsletterSearchListTitle {
					padding: 12px 20px;
				}
				.addItem {
					padding: 10px 0;
					display: grid;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					background-color: rgba(0, 0, 0, 1);
				}
			}
		}
		#newsletterSelectedItems {
			b {
				display: block;
			}
			ul {
				list-style-type: none;
				padding: 0;
				margin: 10px 0 20px;
				border-top: 2px solid #E04E39;
				li {
					border-bottom: 1px solid #DCDCDC;
					border-left: 1px solid #DCDCDC;
					border-right: 1px solid #DCDCDC;
					background-color: white;
					display: grid;
					grid-template-columns: 40px 1fr 40px;
					cursor: move;
					.newsletterSearchListTitle {
						padding: 12px 20px;
					}
					.removeItem {
						padding: 10px 0;
						text-align: center;
						cursor: pointer;
						background-color: rgba(0, 0, 0, 1);
						display: grid;
						align-items: center;
						justify-content: center;
					}
					.itemPosition {
						font-weight: bold;
						font-size: 18px;
						padding: 10px 0;
						display: grid;
						align-items: center;
						justify-content: center;
						cursor: move;
						border-left: 1px solid #DCDCDC;
					}
				}
			}
		}
	}
}
</style>